<template>
  <h1>Place order</h1>
  <button @click.prevent="makePayment">Pay Now</button>
</template>

<script>
/* global Razorpay */
import { defineComponent } from "vue";
import { myhttp } from "@/data/myhttp";

export default defineComponent({
  setup() {
    const orderData = { productId: 3, currencyId: 1, amount: 32.12 };
    function makePayment() {
      // 1. GENERATE ORDER_ID using razorpay/order api
      myhttp
        .post("payment/OrderGenerate/", orderData)
        .then((resp) => {
          var rzp1 = new Razorpay({ ...resp.data, handler: handler });
          rzp1.open();
        })
        .catch((err) => {
          console.log("ERR", err);
        });
    }
    function verifySignature(response) {
      myhttp
        .post("payment/verify", response)
        .then((res) => {
          console.log("PAYMENT RESPONSE", res);
        })
        .catch((err) => {
          console.log("error" + err);
        });
    }
    function handler(response) {
      verifySignature(response);
    }
    return { orderData, makePayment };
  },
});
</script>

<style></style>
